(function($) {
    'use strict';

    $('.c-navicon').on('click', function () {
        $('body').trigger('revealOffcanvas');
    });

    $('.c-page-header__book').click(function() {
        $('body').toggleClass('header-booking--is-active');
    });

    $('.c-page-header__book--activator').click(function() {
        $('html, body').addClass('booking-modal--is-active');
    });

    $('.c-booking-modal__close').click(function() {
        $('html, body').removeClass('booking-modal--is-active');
    });

    $('.ht-tab-header__trigger').on('click', function() {
        $('.ht-tab').toggleClass('revealed');
        $('.ht-tab-header').toggleClass('revealed');
        $('.ht-tab-header__heading').toggleClass('revealed');
        $('.ht-tab-header__icon').toggleClass('revealed');
        $('.ht-tab-header__close').toggleClass('revealed');
        $('.ht-tab-body').toggleClass('revealed');
    });

    var clampSelectors = [
        {
            cssSelector: '.c-grid-filter--posts .c-grid-filter__item .c-cta__entry',
            lineLength: 3
        }
    ];

    $.each(clampSelectors, function(index, clamp) {
        $(clamp.cssSelector).each(function(index, element) {
            $clamp(element, {clamp: clamp.lineLength});
        });
    });


//Smooth scroll  

    // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&  location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 185
                }, 1000, function() {
                    // Callback after animation
                });
            }
        }
    });


    // Wrap every letter in a span

    $('.c-hero__subheading').each(function(){
        $(this).html($(this).text().replace(/([^\x80-\xFF]|\w)/g, "<span class='c-hero__heading-letter'>$&</span>"));
    });

    anime.timeline()
    .add({
        targets: '.c-hero__heading-letter',
        translateX: [40,0],
        translateZ: 0,
        opacity: [0,1],
        easing: "easeOutExpo",
        duration: 1200,
        autoplay: false,
        delay: function(el, i) {
          return 1500 + 40 * i;
        }
    });

     /**
     * Detect lang attribute and then change input placeholder text
     */

    if ($('html').is(':lang(fr)')) {
        $('.c-booking-bar--arrive .c-booking-bar__date').attr('placeholder', 'Date d’arrivée');
        $('.c-booking-bar--depart .c-booking-bar__date').attr('placeholder', 'Date de départ');
    }



      if (document.getElementById('spapage')) {
        $('body').addClass('is--spa-page');
    } else {

    }

    class Offcanvas {
        constructor() {
            this.body = $('body')
            this.offcanvasEl = $('.c-offcanvas')
            this.menuEl = this.offcanvasEl.find('.c-nav-primary')
            this.subMenus = this.menuEl.find('.sub-menu')
            this.menuItemsWithChildren = this.menuEl.find('.menu__item--has-children > a')
            this.close = this.menuEl.find('.c-offcanvas__close')
            this.navicon = $('.c-navicon')
    
            this.createBackButtons()
    
            // Handle clicking a menu item with children
            this.menuItemsWithChildren.on('click', this.revealSubMenu)
    
            // Handle clicking a sub menu back button
            this.menuEl.on('click', '.menu__button', this.hideSubMenu)

            // Handle clicking the navicon when the subnav is open
            this.navicon.on('click', this.hideAllSubMenus)

        }
    
        revealSubMenu(event) {
            event.preventDefault()
            $('.sub-menu').removeClass('is-active')
            let menuItem = $(event.target).parent()
            menuItem.find('> .sub-menu').addClass('is-active')
        }
    
        hideSubMenu(event) {
            event.preventDefault()
            let subMenu = $(event.target).parent()
            subMenu.removeClass('is-active')
        }

        hideAllSubMenus(){
            $('.sub-menu').removeClass('is-active')
        }
    
        createBackButtons() {
            this.subMenus.each(function () {
                $(this).prepend('<button class="menu__button"/>')
            })
        }
    }

    new Offcanvas()

})(jQuery);
